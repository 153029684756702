// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/header.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { Image } from "antd";
const Header = ({
  image
}) => {
  return <div className="relative h-[137px]">
      <div className="main-container h-[90px] bg-gradient-to-r from-[#ADE8B5] to-[#20F83C] rounded-tl-2xl rounded-tr-2xl">
        {/* {title && (
          <Title
            level={3}
            className="text-center "
            style={{ color: "#4C485C" }}
          >
            {title}
          </Title>
         )} */}
      </div>
      {image && <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] p-8">
          <Image width={90} height={90} src={image} preview={false} />
        </div>}
    </div>;
};
_c = Header;
export default Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;