// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/MainContainer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/MainContainer.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import React from "react";
import Header from "./header/header";
const MainContainer = ({
  children,
  headerImage,
  headerTitle
}) => <div className="flex h-[calc(100dvh-3dvh)] bg-[#D9D9D9] justify-center " style={{
  padding: 16
}}>
    <div className="bg-white flex flex-col w-full max-w-screen-sm md:max-w-1/2 rounded-2xl">
      {(headerImage || headerTitle) && <Header image={headerImage} title={headerTitle} />}
      {children}
    </div>
  </div>;
_c = MainContainer;
export default MainContainer;
var _c;
$RefreshReg$(_c, "MainContainer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;